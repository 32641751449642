import { privateRequest } from 'config/axios.config'
import { useEffect, useMemo } from 'react'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

interface Trade {
  _id: string
  pair: string
  openPrice: number
  closePrice: number
  amount: number
  direction: string
  status: string
  openTimestamp: string
  closeTimestamp: string
  exchange: string
}

export default function AiTerminalsPage() {
  const {
    data: trades,
    isLoading,
    error,
  } = useQuery<Trade[], Error>(
    'trading-data',
    async () => {
      try {
        const res = await privateRequest.get('user/trading')
        console.log(res.data.data)
        return res.data.data || []
      } catch (error) {
        errorHandler(error)
        throw error
      }
    },
    {
      refetchInterval: 60000, // Fetch new data every 1 minute (60000ms)
      refetchOnWindowFocus: true, // Prevent refetch when window gains focus
      refetchIntervalInBackground: true,
      staleTime: 0,
    },
  )

  // Log trades when data is available
  useEffect(() => {
    if (trades) {
      console.log('Fetched Trades:', trades)
    }
  }, [trades])

  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleString()
  }
  // const formatDate = (dateString: string): string => {
  //   const date = new Date(dateString)
  //   const day = String(date.getUTCDate()).padStart(2, '0')
  //   const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-indexed
  //   const year = date.getUTCFullYear()
  //   const hours = String(date.getUTCHours()).padStart(2, '0')
  //   const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  //   const seconds = String(date.getUTCSeconds()).padStart(2, '0')

  //   return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
  // }

  const formatPrice = (price: number): string => {
    return price.toFixed(4)
  }

  const { winRate } = useMemo(() => {
    if (!trades || trades.length === 0) {
      return { winRate: 0 }
    }

    const totalTrades = trades.length
    const winningTrades = trades.filter((trade) => {
      if (trade.direction === 'long') {
        return trade.closePrice > trade.openPrice
      } else {
        return trade.closePrice < trade.openPrice
      }
    }).length

    const winRate = (winningTrades / totalTrades) * 100

    return { totalTrades, winRate }
  }, [trades])

  return (
    <div className='flex-1 bg-gray-900 text-white h-screen flex flex-col  '>
      <div className='body-area flex-grow overflow-hidden '>
        <div className='bg-gray-800 rounded-lg shadow-lg h-full flex flex-col'>
          {/* <div className='flex justify-between items-center  pb-9 bg-gray-700'>
            <h1 className='text-2xl font-bold'>Trading Performance</h1>
          </div> */}
          {/* Header Section */}
          <div className='flex justify-between items-center py-4 bg-gray-700'>
            <h1 className='text-2xl font-bold '>Trading Performance</h1>
            <div className='stats flex space-x-4 text-sm'>
              <div className='stat-item'>
                <span className='text-gray-400'>Win Rate:</span>
                <span className='ml-2 font-semibold text-green-600'>{winRate.toFixed(2)}%</span>
              </div>
            </div>
          </div>

          {/* Loading and Error Handling */}
          {isLoading ? (
            <div className='text-center text-white py-4'>Loading...</div>
          ) : error ? (
            <div className='text-center text-red-500 py-4'>Error: {error.message}</div>
          ) : (
            <div className='flex-grow overflow-auto'>
              <table className='w-full border-collapse borde-none'>
                <thead className='bg-[#151219] text-gray sticky top-0'>
                  <tr className='text-left  text-sm'>
                    <th className='py-2 text-[15px]'>Timestamp </th>
                    <th className='px-4 text-[15px] py-2'>Pair</th>
                    <th className='px-4 text-[15px] py-2'>Type</th>
                    <th className='px-4 text-[15px] py-2'>Status</th>
                    <th className='px-4 text-[15px] py-2'>Entry Price</th>
                    <th className='px-4 text-[15px] py-2'>Exit Price</th>

                    <th className='px-4 text-[15px] py-2'>Exchange</th>
                    <th className='px-4 text-[15px] py-2'>Profit/Loss</th>
                  </tr>
                </thead>

                <tbody>
                  {trades?.map((trade) => {
                    const profitLossPercent =
                      trade.direction === 'long'
                        ? (((trade.closePrice - trade.openPrice) / trade.openPrice) * 100).toFixed(
                            2,
                          )
                        : (((trade.openPrice - trade.closePrice) / trade.openPrice) * 100).toFixed(
                            2,
                          )

                    return (
                      <tr key={trade._id} className='border-b border-[#313131] text-sm'>
                        <td className='px-4 text-[13px] py-2'>
                          {formatDate(trade.closeTimestamp)}
                        </td>
                        <td className='px-4 text-[13px] py-2'>{trade.pair}</td>
                        <td className='px-4 text-[13px] py-2'>{trade.direction}</td>
                        <td className='px-4 text-[13px] py-2'>{trade.status}</td>
                        <td className='px-4 text-[13px] py-2'>${formatPrice(trade.openPrice)}</td>
                        <td className='px-4 text-[13px] py-2'>${formatPrice(trade.closePrice)}</td>

                        <td className='px-4 text-[13px] py-2'>{trade.exchange}</td>
                        <td className='px-4 text-[13px] py-2'>
                          <span
                            className={`flex items-center gap-1 ${
                              parseFloat(profitLossPercent) >= 0 ? 'text-green-600' : 'text-red-500'
                            }`}
                          >
                            {parseFloat(profitLossPercent) >= 0 ? <FaArrowUp /> : <FaArrowDown />}
                            {profitLossPercent}%
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
